.privacy-policy-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  font-family: "Poppins", sans-serif;
}

.privacy-policy-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-policy-content h1, .privacy-policy-content h2, .privacy-policy-content h3, .privacy-policy-content h4 {
  color: #333333;
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-policy-content p {
  font-size: 14px;
  color: #666666;
  margin-bottom: 20px;
}

/* Responsive styles */
@media (min-width: 768px) {
  .privacy-policy-container {
    padding: 40px;
  }

  .privacy-policy-content h1, .privacy-policy-content h2, .privacy-policy-content h3, .privacy-policy-content h4 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .privacy-policy-content p {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .privacy-policy-content h1, .privacy-policy-content h2, .privacy-policy-content h3, .privacy-policy-content h4 {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .privacy-policy-content p {
    font-size: 18px;
  }
}
