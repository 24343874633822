/* src/css/AppPromo.css */
.app-promo {
  text-align: center;
  padding: 40px 20px;
  font-family: "Poppins", sans-serif;
  max-width: 1200px; /* Limit the maximum width */
  margin: 0 auto; /* Center the component */
}

.app-promo h2 {
  font-size: 28px;
  margin-bottom: 30px;
  color: #000000;
}

.app-buttons img {
  object-fit: fit; /* Ensure the images scale correctly */
  margin: 0 10px;
}

@media (min-width: 768px) {
  .app-promo h2 {
    font-size: 36px;
    margin-bottom: 25px;
  }

  .app-buttons {
    flex-direction: row;
  }

  .app-buttons img {
    object-fit: contain;
  margin: 10px 0;
  width: 200px;
  gap: 18px;
  }
}

@media (min-width: 1024px) {
  .app-promo h2 {
    font-size: 48px;
    margin-bottom: 30px;
  }

  .app-buttons img {
    width: 150px;
    gap: 20px;
  }
}
