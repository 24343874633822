.contact-us-page {
  background-color: #ffffff;
  padding: 40px 20px;
  font-family: "Poppins", sans-serif;
}

.contact-header {
  text-align: left;
  margin-bottom: 40px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.contact-header h2 {
  color: #662C90;
  margin-bottom: 10px;
  font-size: 18px;
}

.contact-header h1 {
  font-size: 28px;
  color: #333333;
  margin-bottom: 0;
}

.contact-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  max-width: 100%;
}

.important {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  max-width: 100%;
  text-align: left;
}

.important h3 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 20px;
}

.important p {
  margin: 0;
  color: #666666;
}

.contact-methods {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
}

.method {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.contact-icon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.method p {
  margin: 0;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.contact-form-wrapper {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  max-width: 100%;
}

.contact-form {
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;  /* Add consistent gap between form elements */
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;  /* Add gap between label and input */
}

.form-group label {
  font-size: 14px;
  color: #333333;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 12px 15px;
  font-size: 14px;
  border: none;
  border-radius: 10px;
  background-color: #f7f5f9;
  outline: none;
  box-sizing: border-box;
}

.form-control::placeholder {
  color: #888888;
}

.form-group select {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 20px;
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

button[type="submit"] {
  padding: 15px;
  background-color: #662C90;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

button[type="submit"]:hover {
  background-color: #5a278a;
}

.input-icon-wrapper {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  color: #888888;
}

.form-control.with-icon {
  padding-left: 45px;
}
.form-group textarea {
  resize: vertical;
  height: 150px;
  padding: 10px;
  border-radius: 25px;
  background-color: #f0f0f0;
  outline: none;
  box-sizing: border-box;
}

.loading-icon {
  animation: rotate 1s linear infinite;
}

.loading-circle {
  stroke-dasharray: 60;
  stroke-dashoffset: 60;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 60;
  }
  50% {
    stroke-dashoffset: 30;
  }
  100% {
    stroke-dashoffset: 60;
  }
}

/* Modify the existing button styles to accommodate the loading icon */
button[type="submit"] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

button[type="submit"]:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (min-width: 768px) {
  .contact-header h1 {
    font-size: 36px;
  }

  .contact-content {
    flex-direction: row;
    align-items: flex-start;
  }

  .contact-methods {
    grid-template-columns: 1fr 1fr;
  }

  .contact-info {
    max-width: 50%;
  }

  .contact-form-wrapper {
    max-width: 50%;
  }
}

@media (min-width: 1024px) {
  .contact-header h1 {
    font-size: 48px;
  }

  .contact-content {
    gap: 150px;
  }

  .contact-info {
    max-width: 40%;
  }

  .contact-form-wrapper {
    max-width: 60%;
  }
}

@media (max-width: 767px) {
  .contact-methods {
    grid-template-columns: 1fr;
  }

  .contact-info {
    max-width: 100%;
  }

  .contact-form-wrapper {
    max-width: 100%;
  }

  .method {
    flex-direction: column;
    align-items: center;
  }

  .contact-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
