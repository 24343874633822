.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  font-family: "Poppins", sans-serif;
}

.login-container {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.login-logo {
  width: 150px;
  margin-bottom: 20px;
}

.login-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 30px;
}

.login-input-group {
  display: flex;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 25px;
  overflow: hidden;
}

.login-country-code-selector {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-right: 1px solid #ccc;
  min-width: 80px;
}

.login-flag-icon {
  width: 20px;
  margin-right: 5px;
}

.login-country-select {
  border: none;
  background: transparent;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px;
  cursor: pointer;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}

.login-phone-input {
  flex-grow: 1;
  border: none;
  padding: 15px;
  font-size: 16px;
}

.login-submit-button {
  width: 100%;
  padding: 15px;
  background-color: white;
  color: #662C90;
  border: 2px solid #662C90;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-submit-button:hover {
  background-color: #662C90;
  color: white;
}

.login-submit-button:disabled {
  background-color: #cccccc;
  color: #666666;
  border-color: #cccccc;
  cursor: not-allowed;
}

.login-error-message {
  color: #ff0000;
  margin-top: 10px;
  font-size: 14px;
}

/* Remove focus outlines for cleaner look */
.login-phone-input:focus,
.login-country-select:focus,
.login-submit-button:focus {
  outline: none;
}

/* Style the dropdown options */
.login-country-select option {
  font-size: 16px;
  padding: 5px;
}

@media (max-width: 768px) {
  .login-container {
    padding: 20px;
  }

  .login-submit-button {
    padding: 10px 15px;
    font-size: 14px;
  }
}