.refer-a-friend-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.refer-a-friend-page h1 {
  font-size: 36px;
  color: #000000;
  margin-bottom: 20px;
}

.refer-a-friend-page p {
  font-size: 16px;
  color: #666666;
  margin-bottom: 30px;
}

.refer-step {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border: 1px solid #666666;
  flex-direction: column;
}

.refer-step-icon {
  margin-bottom: 20px;
}

.refer-step-icon img {
  width: 60px; /* Adjust size as needed */
  height: auto;
}

.refer-step-content {
  text-align: center;
}

.refer-step-content h2 {
  font-size: 20px;
  color: #333333;
  margin-bottom: 10px;
}

.refer-step-content p {
  font-size: 14px;
  color: #666666;
  margin: 0;
}

/* Responsive styles */
@media (min-width: 768px) {
  .refer-a-friend-page h1 {
    font-size: 48px;
  }

  .refer-a-friend-page p {
    font-size: 18px;
  }

  .refer-step {
    flex-direction: row;
    padding: 50px;
  }

  .refer-step-icon {
    flex: 0 0 100px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .refer-step-content {
    text-align: left;
    padding-bottom: 0;
  }

  .refer-step-content h2 {
    font-size: 24px;
  }

  .refer-step-content p {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .refer-a-friend-page h1 {
    font-size: 56px;
  }
}
