.footer {
  background-color: #ffffff;
  padding: 40px 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
  font-family: "Poppins", sans-serif;
}

.footer-logo {
  text-align: left;
  flex: 1;
}

.footer-logo img {
  width: 100px;
  height: auto;
}

.footer-logo p {
  margin: 10px 0;
  color: #666666;
}

.footer-social {
  margin-top: 10px;
  text-align: left;
}

.footer-social a {
  margin: 0 10px;
}

.footer-social img {
  width: 24px;
  height: 24px;
}

.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 2;
}

.footer-column {
  text-align: left;
  margin-right: 20px;
}

.footer-column h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: #333333;
  text-decoration: none;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #eaeaea;
  padding-top: 10px;
  font-family: "Poppins", sans-serif;
}

.footer-bottom p {
  margin: 0;
  color: #666666;
}

.footer-bottom a {
  color: #333333;
  text-decoration: none;
  margin-left: 10px;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    text-align: center;
    margin: 0;
  }

  .footer-logo,
  .footer-social {
    text-align: center;
  }
}
