.transaction-history-container {
    display: flex;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .sidebar {
    width: 200px;
    padding-right: 20px;
  }
  
  .sidebar-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    color: #888;
  }
  
  .sidebar-item.active {
    color: #7E3CC3;
    font-weight: bold;
  }
  
  .transaction-history-content {
    flex-grow: 1;
    padding-left: 20px;
  }
  
  .transaction-filters {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .filter-button {
    padding: 6px 12px;
    border: 1px solid #662C90;
    border-radius: 20px;
    background-color: white;
    color: #662C90;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 14px;
  }
  
  .filter-button.active,
  .filter-button:hover {
    background-color: #662C90;
    color: white;
  }
  
  .transaction-list {
    padding: 10px;
  }

  .transaction-history-content h2 {
    text-align: left;
  }
  
  .transaction-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    border: 1px solid #662C90;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .transaction-item h2 {
    align-items: left;
  }
  
  .transaction-description {
    flex-grow: 1;
  }
  
  .transaction-description p {
    margin: 5px 0;
    text-align: left;
  }
  
  .transaction-date {
    color: #888;
  }
  
  .transaction-status {
    color: #888;
  }
  
  .transaction-amount {
    text-align: right;
  }
  
  .transaction-amount p {
    font-weight: bold;
  }