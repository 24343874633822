.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .responsive-heading {
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .responsive-text {
    font-size: clamp(1rem, 2vw, 1.2rem);
    line-height: 1.5;
    text-align: center;
    max-width: 800px;
    margin: 0 auto 2rem;
  }
  
  .country-selector {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .pay-as-you-go-section {
    max-width: 800px;
    margin: 0 auto 3rem;
    text-align: center;
  }
  
  .payg-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .paygplan {
    background-color: #EBE3F3;
    padding: 1rem;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .paygitem-price {
    color: #43A54D;
    font-weight: bold;
  }
  
  .topup-login-button {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: #662C90;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }
  
  .topup-login-button:hover {
    background-color: #4e2170;
  }
  
  .features-and-phone-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 3rem;
  }
  
  .app-features {
    flex: 1;
    max-width: 60%;
  }
  

  
  .app-download {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .store-badge, .qr-code {
    max-width: 150px;
    height: auto;
  }
  
  .phone-image-section {
    flex: 1;
    max-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .phone-image-right {
    max-width: 100%;
    height: auto;
  }
  
  .calling-plans-section {
    margin-bottom: 3rem;
  }
  
  .plan-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }
  
  .plan {
    background-color: #EBE3F3;
    padding: 1rem;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }
  
  .planitem {
    background: #662C901A;
    border-radius: 12px;
    color: #662C90;
    padding: 5px;
    margin-top: 0.5rem;
    display: inline-block;
  }
  
  .benefits-section, .testimonials-section, .faq-section {
    margin-top: 3rem;
  }
  
  .benefits-cards, .testimonials-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .faq-accordion {
    margin-top: 2rem;
  }
  
  @media (max-width: 992px) {
    .features-and-phone-section {
      flex-direction: column;
    }
  
    .app-features, .phone-image-section {
      max-width: 100%;
    }
  
    .phone-image-section {
      display: none;
  }
}
  
  @media (max-width: 768px) {
    .payg-list, .plan-list {
      grid-template-columns: 1fr;
    }
  
    .app-download {
      justify-content: center;
    }
  
   
  }
  
  